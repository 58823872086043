<template>
  <div>
    Yükleniyor
  </div>
</template>

<script>
import { Main } from '../styled';
import SvgConverter from '@/components/utilities/SvgConverter.vue';
import WorldMap from '@/components/utilities/VectorMap.vue';
import { SalesTargetWrap, LocationMapWrapper } from '../dashboard/style';
import HalfProgressBar from '../../components/utilities/progressBar';
import { OverviewSalesCard, OverviewCard } from '../dashboard/style';
import { inject } from 'vue';

export default {
  name: 'Redirect',
  data() {
    return {};
  },
  methods: {

    get() {
      console.log('get start');
      this.$appAxios.post(this.$endpoints.getAuthTypeAdmin, {}).then(resp => {
        console.log("respdata: ",resp);
        localStorage.setItem('jwtContract', resp.data);
        console.log('get ok');
      }).then(() => {
        this.$router.push('/');
      });

      /**
       * Yeni Sistem İçin
       */
      // localStorage.setItem('jwtContract', process.env.VUE_APP_SET_CONTRACTED);
      // this.$router.push('/records');
      // console.log('get end');
    },
  },
  mounted() {
    this.get();
  },
  components: {
    Main,
    SalesTargetWrap,
    HalfProgressBar,
    SvgConverter,
    WorldMap,
    LocationMapWrapper,
    OverviewSalesCard,
    OverviewCard,
  },
};
</script>
